/**
 * Created by leonardogasparini on 22/09/16.
 */

$(document).ready(function () {

  $.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
  });

  /* ------------------------------------------------
   Form styler
   ------------------------------------------------ */

  (function ($) {
    $(function () {

      $('input, select').styler();

    });
  })(jQuery);

  /* ------------------------------------------------
   End of Form styler
   ------------------------------------------------ */

  /*******************************  DOWNLOAD DISEGNI  ********************************/

  /*******************************  CALCOLO RISPARMIO  ********************************/

  $('#send-draws-form').on('submit', function (event) {

    event.preventDefault();

    var method = $(this).attr('method');
    var action = $(this).attr('action');

    var data = $(this).serialize();

    $.ajax({
      url: action,
      type: method,
      dataType: 'json',
      data: data,
    })
      .success(function (msg) {

        $('#response').empty();

        $('#send-draws-form input:not([type=hidden])').val('');

        var response = '<div class="col-md-12"><div class="alert alert-success align_center" role="alert">' + msg + '</div></div>';

        $(response).appendTo('#response');

      })
      .fail(function (errors) {

        $('#response').empty();

        console.log(errors);

        if (typeof errors === 'object') {
          $('<div class="col-md-12"><div class="alert alert-warning" role="alert"></div></div>').appendTo('#response');
          $.each(errors.responseJSON, function (field, message) {
            var error = '<p><i class="fa fa-exclamation-triangle"></i> ' + message + '</p>';
            $(error).appendTo('#response .alert');
          })
        }

      })

  })

  /*******************************  /DOWNLOAD DISEGNI  ********************************/

  /*******************************  COOKIES  ********************************/

  $(document).on('click', '.cookie_law_banner_accept', function (event) {
    document.cookie = "EU_COOKIE_LAW_CONSENT=true";
    $('.cookie_law_banner_close').click();
  });

  $(document).on('click', '.cookie_law_banner_close', function (event) {
    $(this).closest('.cookie_law_banner').hide();
  });

  /*******************************  /COOKIES  ********************************/

});